import React, { useRef } from "react";
import _ from 'lodash';
import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import FilterDrawer from "../FilterDrawer";
import DataTableLoader from "../DataTableLoader";
import {withStyles} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import Box from "@material-ui/core/Box";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import FilterListIcon from '@material-ui/icons/FilterList';
import WarningIcon from '@material-ui/icons/Warning';
import {IconButton} from "@material-ui/core";
import {fallbackValue, fullDate, shortDate} from "../../utils/utils";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Pagination from "@material-ui/lab/Pagination";
import Grid from "@material-ui/core/Grid";

const customStyles = makeStyles({
    tableHead: {
        background: '#E8E7EE',
        height: "48px"
    },
    tableHeadFilterContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    tableHeadFilter: {
        width: '40px',
        textAlign: 'center',
        padding: '0px !important'
    },
    headerCell: {
        color: 'black'
    },
    headerCellBox: {
      display: "flex"
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
});

const CustomTableHead = ({ classes, order, orderBy, sortingAvailable, onRequestSort, fields, onFilterClick, filterOpen, t, enableFilter }) => {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={classes.tableHead}>
            <TableRow>
                {
                    enableFilter && (
                        <TableCell component="th" className={classes.tableHeadFilter} style={filterOpen ? {background: '#E3D900'} : {background: '#00BAC7'}}>
                            <div className={classes.tableHeadFilterContainer} >
                                <IconButton size="small" onClick={onFilterClick}>
                                    <Tooltip title={t('messages.filter')}>
                                        <FilterListIcon />
                                    </Tooltip>
                                </IconButton>
                            </div>
                        </TableCell>
                    )
                }
                {fields.length > 0 &&
                    fields.map((field) => (
                        <TableCell
                            className={classes.headerCell}
                            key={field.name}
                            component="th"
                        >
                            <Box className={classes.headerCellBox}>
                                { sortingAvailable && field.sorting !== false ?
                                    <TableSortLabel
                                        active={orderBy === field.name}
                                        direction={orderBy === field.name ? order : 'asc'}
                                        onClick={createSortHandler(field.name)}
                                        style={{ color: "black" }}
                                    >
                                        {t(`keys.${field.label ? field.label : field.name}`)}
                                        {orderBy === field.name ? (
                                            <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                        ) : null}
                                    </TableSortLabel>
                                    : <label style={{ color: "black" }}>{t(`keys.${field.label ? field.label : field.name}`, {value: field.name})}</label>
                                }
                            </Box>
                        </TableCell>
                ))}
                
                 
            </TableRow>
        </TableHead>
    )
}

const renderCell = (field, item, t) => {
    switch (field.type) {
        case 'string':
            return item;
        case 'translatedString':
            return t(`values.${field.name}.${item}`);
        case 'translatedStringTranslationKey':
            return t(`values.${field.translationKey}.${item}`);
        case 'length':
            return item.length;
        case 'translatedArray':
            let translatedArrayString = '';
            const itemLength = item.length;
            item.forEach((itemValue, index) => {
                translatedArrayString = translatedArrayString + t(`values.${field.name}.${itemValue}`) + (index === itemLength - 1 ? '' : ' | ');
            })
            return translatedArrayString;
        case 'stringArray':
            let arrayString = '';
            const arrayLength = item.length;
            item.forEach((itemValue, index) => {
                arrayString = arrayString + itemValue + (index === arrayLength - 1 ? '' : ' | ');
            })
            return arrayString;
        case 'fullDate':
            return item ? fullDate(item, field.countryCode) : t(`messages.${fallbackValue[field.name]}`);
        case 'shortDate':
            return item ? shortDate(item, field.countryCode) : t(`messages.${fallbackValue[field.name]}`);
        case 'expiryDate':
            return item ? (new Date(item) < new Date() ? <span className="color-danger display-flex align-items-center"><WarningIcon className="font-size-1 margin-right-10" /> {fullDate(item, field.countryCode)}</span> : fullDate(item, field.countryCode)) : t(`messages.${fallbackValue[field.name]}`);
        case 'isFieldEmpty':
            return item ? t('values.true') : t('values.false');
        default:
            return item;
    }
}


const ArticleResidualTable = ({ fields, itemsResponse, filterKeys, filterValues, onInputChange, onValueSubmit, sorting, onSort, sortingAvailable, onCancel, showContentLoader, enableFilter, countryCarrier, numberOfMonths, fetchData }) => {
    const { t } = useTranslation();
    const [filterOpen, setFilterOpen] = React.useState(false);
    const [filter] = React.useState(false);
    const classes = customStyles();

    const items = itemsResponse.articlesResidualResponse ? itemsResponse.articlesResidualResponse : {};

    const handleRequestSort = (event, columnName) => {
        const sortingDirection = sorting.sort === 'desc' ? 'asc' : 'desc';
        onSort(columnName, sortingDirection)
    };

    const handleFilterClick = () => {
        setFilterOpen(!filterOpen)
    }

    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        onCancel(flattenCountryCarrier[newValue].carrierCode, 1);
    };

    const handlePageChange = (event, page) => {
        fetchData(flattenCountryCarrier[activeTab].carrierCode, page);
    };

    var flattenCountryCarrier = [];
    Object.keys(countryCarrier).forEach(countryCode => {
        countryCarrier[countryCode].forEach(carrierCode => {
            flattenCountryCarrier.push({
                key: `${countryCode}${carrierCode}`,
                label: `${countryCode} ${carrierCode}`,
                countryCode: countryCode,
                carrierCode: carrierCode
            });
        });
    });

    return (
        <div style={{overflow: 'auto'}}>
            <Table size="small">
                <CustomTableHead classes={classes}
                                 order={sorting.sort}
                                 orderBy={sorting.orderBy}
                                 sortingAvailable={sortingAvailable}
                                 onRequestSort={handleRequestSort}
                                 filter={filter}
                                 fields={fields}
                                 onFilterClick={handleFilterClick}
                                 filterOpen={filterOpen}
                                 t={t}
                                 enableFilter={enableFilter}
                />
                <TableBody>
                    {
                        filterOpen &&
                        <TableRow>
                            <TableCell colSpan={fields.length + 1}>
                                <FilterDrawer
                                    filterKeys={filterKeys}
                                    filterValues={filterValues}
                                    onInputChange={(filterKey, filterValue, multipleSelection) => onInputChange(filterKey, filterValue.target.value, multipleSelection)}
                                    onValueSubmit={() => onValueSubmit(flattenCountryCarrier[activeTab].carrierCode)}
                                    onCancel={() => onCancel(flattenCountryCarrier[activeTab].carrierCode)}
                                />
                            </TableCell>
                        </TableRow>
                    }

                    <TableRow>
                        <TableCell style={{background: '#E8E7EE', padding: '0'}} colSpan={fields.length + 1}>
                            <Tabs value={activeTab} onChange={handleTabChange}>
                                {flattenCountryCarrier.map((item, index) => {
                                    return (
                                        <Tab key={index} label={item.label} />
                                    )
                                })}
                            </Tabs>
                        </TableCell>
                    </TableRow>
                    
                    {showContentLoader ? <TableRow>
                        <TableCell colSpan={fields.length +1}>
                            <DataTableLoader />
                        </TableCell>
                    </TableRow> : items != null && Object.keys(items).length !== 0 ? (
                        Object.entries(items).map(([countryCode, countryCodeValue]) => {
                            return (
                                <>
                                    { items != null && !_.isEmpty(items) &&
                                        Object.entries(items[countryCode]).map(
                                            ([carrierCode, carrierValue], carrierIndex) => {
                                                return (
                                                    <>
                                                        <TableRow>
                                                            <TableCell colSpan={fields.length + 1}>
                                                                <Grid container justifyContent="center">
                                                                    <Pagination count={carrierValue['totalPageNumber']} page={carrierValue['currentPageNumber']} onChange={handlePageChange}/>
                                                                </Grid>
                                                            </TableCell>
                                                        </TableRow>
                                                        {
                                                            carrierValue['articleResiduals'].map((articleResidual) => {
                                                                return (
                                                                    <TableRow key={articleResidual.sku}>
                                                                        <TableCell></TableCell>
                                                                        <TableCell>{articleResidual.sku}</TableCell>
                                                                        <TableCell>{articleResidual.description}</TableCell>
                                                                        <TableCell>{articleResidual.suggestedRetailPriceLimit}</TableCell>
                                                                        {
                                                                            Array.from({ length: numberOfMonths }, (_, index) => {
                                                                                const priceValue = index < articleResidual.residualPrice.length 
                                                                                    ? articleResidual.residualPrice[index].value 
                                                                                    : '';  

                                                                                return (
                                                                                    <TableCell>{priceValue}</TableCell>
                                                                                );
                                                                            })
                                                                        }
                                                                    </TableRow>
                                                                )
                                                            })
                                                        }
                                                        <TableRow>
                                                            <TableCell colSpan={fields.length + 1}>
                                                                <Grid container justifyContent="center">
                                                                    <Pagination count={carrierValue['totalPageNumber']} page={carrierValue['currentPageNumber']} onChange={handlePageChange}/>
                                                                </Grid>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                )
                                            }
                                        )
                                    }
                                </>
                            )
                        })
                    ) : (
                        <TableRow>
                            <TableCell colSpan={fields.length}>
                                <p style={{ textAlign: "left" }}> {t('messages.noDataFound')}</p>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    )
}

export default ArticleResidualTable;
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect, Switch } from "react-router-dom";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import NavigationBar from "../components/NavigationBar";
import themeBolttech from "../theme";
import UserList from "./User/UserList";
import UserDetails from "./User/UserDetails";
import LoginPage from "./User/LoginPage";
import {
  changePassword,
  getOtp,
  logIn,
  logOut,
  sendForgotPasswordEmail,
  setNewPasswordRequest,
  setResetPasswordToken,
  submitForgotPasswordConfirmation,
  thirdPartyLogIn,
  getLoginPageEnabled,
} from "../actions/authenticationActions";
import i18n from "i18next";
import SimpleDialog from "../components/dialogs/SimpleDialog";
import {
  simpleDialogClose,
  showError,
  hideConfirmationDialog,
} from "../actions/commonActions";
import Dashboard from "./Dashboard";
import UserCreate from "./User/UserCreate";
import ForgotPassword from "./User/ForgotPassword";
import PasswordReset from "./User/PasswordReset";
import * as queryString from "query-string";
import ChangePassword from "./User/ChangePassword";
import ConfirmationDialog from "../components/dialogs/ConfirmationDialog";
import MyAccount from "./User/MyAccount";
import { resetUserPassword } from "../actions/userActions";
import SetNewPassword from "./User/SetNewPassword";
import NavigationDrawer from "../components/NavigationDrawer";
import ArticleGlobalList from "./ArticleGlobal/ArticleGlobalList";
import ArticleAccessoryMapping from "./ArticleAccessory/ArticleAccessoryMapping";
import ArticleSeverity from "./ArticleGlobal/ArticleSeverity";
import ArticleLikeForMapping from "./ArticleLikeForMapping/ArticleLikeForMapping";
import ArticleLikeForRecommendationUpdates from "./ArticleLikeForMapping/ArticleLikeForRecommendationUpdates";
import ArticleLikeForUpdateRequest from "./ArticleLikeForMapping/ArticleLikeForUpdateRequest";
import ArticleLikeForRefreshRequest from "./ArticleLikeForMapping/ArticleLikeForRefreshRequest";
import NavigationToolbar from "../components/NavigationToolbar";
import ArticleGlobalCreate from "./ArticleGlobal/ArticleGlobalCreate";
import ArticleGlobalDetails from "./ArticleGlobal/ArticleGlobalDetails";
import ArticleGlobalUpload from "./ArticleGlobal/ArticleGlobalUpload";
import ArticleImageList from "./ArticleImage/ArticleImageList";
import ArticleImageUpload from "./ArticleImage/ArticleImageUpload";
import ArticleLocalizedUpload from "./ArticleLocalized/ArticleLocalizedUpload";
import ArticlelocalizedList from "./ArticleLocalized/ArticlelocalizedList";
import ArticleLocalizedCreate from "./ArticleLocalized/ArticleLocalizedCreate";
import ArticleExcusionList from "./ArticleExclusion/ArticleExcusionList";
import ArticleExclusionCreate from "./ArticleExclusion/ArticleExclusionCreate";
import BrandImageUpload from "./Brand/BrandImageUpload";
import ArticleAuditList from "./Audit/ArticleAuditList";
import ArticleAuditDetails from "./Audit/ArticleAuditDetails";
import ArticleResidualList from "./ArticleResidual/ArticleResidualList";
import ArticleResidualUpload from "./ArticleResidual/ArticleResidualUpload";

const handleLanguageChange = (languageCode, i18n) => {
  i18n.changeLanguage(languageCode);
};

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDashboard: false,
    };
  }

  componentDidMount() {
    const pathname = this.props.location.pathname;
    const showDashboard = pathname === "/" || pathname.endsWith("dashboard");
    this.setState({ showDashboard });
    !this.props.authentication.authenticated &&
      !pathname.startsWith("/forgotPasswordConfirmation") &&
      pathname !== "/forgotPassword" &&
      pathname !== "/setNewPassword" &&
      this.props.history.push("/login");
    this.props.authentication.passwordExpired === "true" &&
      this.props.history.push("/changePassword");
    this.props.authentication.authenticated &&
      this.props.location.pathname === "/" &&
      this.props.history.push("/dashboard");
    if (
      this.props.authentication.authenticated &&
      (pathname.startsWith("/forgotPasswordConfirmation") ||
        pathname.startsWith("/setNewPassword"))
    ) {
      this.props.showError({
        response: {
          data: {
            translationKey: `Please log out before changing other user's password!`,
          },
        },
      });
    }
    this.props.getLoginPageEnabled();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.authentication.authenticated !==
        prevProps.authentication.authenticated &&
      this.props.authentication.authenticated === true
    ) {
      this.props.authentication.authenticated &&
        this.props.history.push("/dashboard");
    }

    if (
      this.props.authentication.passwordExpired !==
        prevProps.authentication.passwordExpired &&
      this.props.authentication.passwordExpired === "true"
    ) {
      this.props.authentication.passwordExpired === "true" &&
        this.props.history.push("/changePassword");
    }
  }

  renderLoginPage(message) {
    return (
      <LoginPage
        onFormSubmit={(data) => this.props.logIn(data)}
        errorMessage={message}
        loginPageEnabled={this.props.authentication.loginPageEnabled}
      />
    );
  }

  renderForgotPasswordPage() {
    return (
      <ForgotPassword
        onFormSubmit={(data) => this.props.sendForgotPasswordEmail(data)}
        errorMessage={this.props.authentication.errorMessage}
        requestInProgress={this.props.common.requestInProgress}
        requestSuccess={this.props.common.requestSuccess}
      />
    );
  }

  renderPasswordResetPage() {
    const parsedQueryString = queryString.parse(this.props.location.search);
    return (
      <PasswordReset
        onFormSubmit={(data) =>
          this.props.submitForgotPasswordConfirmation(data)
        }
        errorMessage={this.props.authentication.errorMessage}
        resetPasswordToken={parsedQueryString.token}
        requestInProgress={this.props.common.requestInProgress}
        requestSuccess={this.props.common.requestSuccess}
      />
    );
  }

  renderSetNewPasswordPage() {
    const parsedQueryString = queryString.parse(this.props.location.search);
    return (
      <SetNewPassword
        onFormSubmit={(data) => this.props.setNewPasswordRequest(data)}
        errorMessage={this.props.authentication.errorMessage}
        setNewPasswordToken={parsedQueryString.token}
        requestInProgress={this.props.common.requestInProgress}
        requestSuccess={this.props.common.requestSuccess}
      />
    );
  }

  renderChangePasswordPage() {
    return (
      <ChangePassword
        onFormSubmit={(data) => this.props.changePassword(data)}
        onLogOut={() => this.handleLogOut()}
        errorMessage={this.props.authentication.errorMessage}
        requestInProgress={this.props.common.requestInProgress}
        requestSuccess={this.props.common.requestSuccess}
      />
    );
  }

  handleLogOut() {
    this.props.logOut();
    this.props.history.push("/login");
  }

  handleDialogClose() {
    this.props.simpleDialogClose();
  }

  handleThirdPartyLogin(token) {
    const arrayToken = token.split('.');
    const tokenPayload = JSON.parse(window.atob(arrayToken[1]));

    const payload = {
        token: token,
        authorities: tokenPayload.authorities,
        user: {
            id: tokenPayload.sub,
            email: tokenPayload.email,
            availableCountryCodes: tokenPayload.availableCountryCodes,
            countryCode: tokenPayload.availableCountryCodes.length > 0 ? tokenPayload.availableCountryCodes[0] : null,
            languageCode: "en",
            authorities: tokenPayload.authorities
        }
    }

    this.props.thirdPartyLogIn(payload);
  }

  render() {
    const pathname = this.props.location.pathname;
    const showDashboard = pathname === "/" || pathname.endsWith("dashboard");
    const showForgotPassword = pathname === "/forgotPassword";
    const showPasswordReset = pathname === "/forgotPasswordConfirmation";
    const showSetNewPassword = pathname === "/setNewPassword";
    const isLoginPage = pathname === "/login";
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    isLoginPage && token && this.handleThirdPartyLogin(token);
    return (
      <MuiThemeProvider theme={themeBolttech}>
        {this.props.authentication.authenticated &&
          this.props.authentication.passwordExpired === "false" && (
            <div style={{ display: "flex" }}>
              <SimpleDialog
                open={this.props.common.dialogOpen}
                message={this.props.common.dialogMessage}
                dialogType={this.props.common.dialogType}
                entity={this.props.common.entity}
                handleDialogClosing={() => this.handleDialogClose()}
                uploadResponseMessage={this.props.common.uploadResponseMessage}
              />
              <ConfirmationDialog
                entity={this.props.common.confirmationDialog.entity}
                field={this.props.common.confirmationDialog.field}
                action={this.props.common.confirmationDialog.action}
                open={this.props.common.confirmationDialog.open}
                previousValue={
                  this.props.common.confirmationDialog.previousValue
                }
                newValue={this.props.common.confirmationDialog.newValue}
                valueType={this.props.common.confirmationDialog.valueType}
                countryCode={this.props.authentication.loggedInUser.countryCode}
                onReject={() => this.props.hideConfirmationDialog()}
                onAccept={() =>
                  this.props[this.props.common.confirmationDialog.onAccept](
                    this.props.common.confirmationDialog.entityId,
                    this.props.common.confirmationDialog.newValue
                  )
                }
                dynamicContent={
                  this.props.common.confirmationDialog.dynamicContent
                }
              />
              <NavigationDrawer
                userAuthorities={
                  this.props.authentication.loggedInUser.authorities
                }
                onLogoutClick={() => this.props.logOut()}
                languageCode={
                  this.props.authentication.loggedInUser.languageCode
                }
                onLanguageChange={(languageCode) =>
                  handleLanguageChange(languageCode, i18n)
                }
                thirdPartyLogInEnabled={!this.props.authentication.loginPageEnabled}
              />

              <div
                style={{
                  width: "100%",
                  height: "100%",
                  minHeight: "100vh",
                  background: "#E5E5E5",
                }}
              >
                <NavigationToolbar
                  userAuthorities={
                    this.props.authentication.loggedInUser.authorities
                  }
                  onLogoutClick={() => this.props.logOut()}
                  languageCode={
                    this.props.authentication.loggedInUser.languageCode
                  }
                  onLanguageChange={(languageCode) =>
                    handleLanguageChange(languageCode, i18n)
                  }
                />
                {showDashboard && <Dashboard />}
                <Switch>
                  <Route
                    exact
                    path="/forgotPassword"
                    component={ForgotPassword}
                  />
                  <Route exact path="/myAccount" component={MyAccount} />
                  {
                    this.props.authentication.loginPageEnabled && 
                    <>
                      <Route exact path="/users" component={UserList} />
                      <Route path="/users/:id" component={UserDetails} />
                      <Route exact path="/usersNew" component={UserCreate} />
                    </>
                  }
                  <Route
                    exact
                    path="/articles/global"
                    component={ArticleGlobalList}
                  />
                  <Route
                    exact
                    path="/articles/accessoryMapping"
                    component={ArticleAccessoryMapping}
                  />
                  <Route
                    exact
                    path="/articles/residual"
                    component={ArticleResidualList}
                  />
                  <Route
                    exact
                    path="/articles/residual/upload"
                    component={ArticleResidualUpload}
                  />
                  <Route
                    exact
                    path="/articles/severity"
                    component={ArticleSeverity}
                  />
                  <Route
                    exact
                    path="/articles/likeForMapping"
                    component={ArticleLikeForMapping}
                  />
                  <Route
                    exact
                    path="/articles/likeForRecommendationUpdates"
                    component={ArticleLikeForRecommendationUpdates}
                  />
                  <Route
                    exact
                    path="/articles/likeForUpdateRequest"
                    component={ArticleLikeForUpdateRequest}
                  />
                  <Route
                    exact
                    path="/articles/likeForRefreshRequest"
                    component={ArticleLikeForRefreshRequest}
                  />
                  <Route
                    exact
                    path="/articles/createGlobal"
                    component={ArticleGlobalCreate}
                  />
                  <Route
                    exact
                    path="/articles/uploadGlobal"
                    component={ArticleGlobalUpload}
                  />
                  <Route
                    exact
                    path="/articles/createLocalized"
                    component={ArticleLocalizedCreate}
                  />
                  <Route
                    exact
                    path="/articles/exclusion"
                    component={ArticleExcusionList}
                  />
                  <Route
                    exact
                    path="/articles/exclusionCreate"
                    component={ArticleExclusionCreate}
                  />
                  <Route
                    exact
                    path="/articles/exclusionRemove"
                    component={ArticleExclusionCreate}
                  />
                  <Route
                    path="/articles/:id"
                    component={ArticleGlobalDetails}
                  />
                  <Route
                    exact
                    path="/articles/localized"
                    component={ArticlelocalizedList}
                  />
                  <Route
                    exact
                    path="/articles/uploadLocalized"
                    component={ArticleLocalizedUpload}
                  />
                  <Route
                    exact
                    path="/audits/articles"
                    component={ArticleAuditList}
                  />
                  <Route
                    path="/audits/articles/:id"
                    component={ArticleAuditDetails}
                  />
                  <Route
                    exact
                    path="/brand/brandImageUpload"
                    component={BrandImageUpload}
                  />
                  <Route exact path="/images" component={ArticleImageList} />
                  <Route
                    exact
                    path="/images/upload"
                    component={ArticleImageUpload}
                  />
                  <Redirect to="/dashboard" />
                </Switch>
              </div>
            </div>
          )}
        {!this.props.authentication.authenticated &&
          !showForgotPassword &&
          !showPasswordReset &&
          !showSetNewPassword &&
          this.renderLoginPage(this.props.authentication.errorMessage)}
        {!this.props.authentication.authenticated &&
          showForgotPassword &&
          !showPasswordReset &&
          this.renderForgotPasswordPage()}
        {!this.props.authentication.authenticated &&
          showPasswordReset &&
          this.renderPasswordResetPage()}
        {!this.props.authentication.authenticated &&
          showSetNewPassword &&
          this.renderSetNewPasswordPage()}
        {this.props.authentication.passwordExpired === "true" &&
          this.renderChangePasswordPage()}
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.authentication,
  common: state.common,
});

export default connect(mapStateToProps, {
  logIn,
  logOut,
  sendForgotPasswordEmail,
  getOtp,
  setResetPasswordToken,
  submitForgotPasswordConfirmation,
  setNewPasswordRequest,
  simpleDialogClose,
  hideConfirmationDialog,
  showError,
  changePassword,
  resetUserPassword: resetUserPassword,
  thirdPartyLogIn,
  getLoginPageEnabled
})(Main);

import React, { Component } from "react";
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    clearArticleResidualFilters,
    getArticleResidualCSVTemplate,
    getArticleResidualList, sortArticleResidual,
    updateFiltersForArticleResidualList,
    getArticleResidualCountryCarrier
} from '../../actions/articleResidualAction';
import ArticleResidualTable from '../../components/Article/ArticleResidualTable'
import PageTemplate from "../../components/PageTemplate";
import Button from "@material-ui/core/Button";
import {
    parseObjectToString,
    validateAuthority
} from "../../utils/utils";
import { showError } from "../../actions/commonActions";
import { AUTHORITY_ADMIN } from "../../constant";
import { Box } from "@material-ui/core";
import _ from 'lodash';
import { Link } from "react-router-dom";
import { NoteAdd } from '@material-ui/icons';

class ArticleResidualList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            requireMultiLocaliseDialog: false,
            templateType: '',
            skuCountryCodes: [],
        }
    }
    componentDidMount() {
        this.props.getArticleResidualCountryCarrier('articleResidual.countryCarrier.listCategory').then(() => {
            const carrierCode = this.retrieveFirstCarrier(this.props.articleResiduals.articleResidualCountryCarrier)
            this.props.getArticleResidualList(`orderBy=createdAt&sort=desc&size=100&page=1&filterFields[]=carrierCode&filterValues[]=${carrierCode}`);
        })
        this.props.clearArticleResidualFilters();
        this.props.sortArticleResidual('createdAt', 'desc');
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.common.refresh !== prevProps.common.refresh && this.props.common.refresh
        ) {
            this.props.getArticleResidualList('orderBy=createdAt&sort=desc');
        }
    }

    retrieveFirstCarrier(countryCarrier) {
        let result = null;
        for (const country of Object.keys(countryCarrier)) {
            if (countryCarrier[country].length > 0){
                result = countryCarrier[country][0];
                break;
            }
        }
        return result;
    }

    onPromptError = (data) => {
        this.props.showError({ response: { data: { translationKey: `${data}` } } });
    }

    handleFilterUpdate(filterKey, filterValue, multipleSelection) {
        console.log("filterKey "+ filterKey);
        console.log("filterValue "+ filterValue);

        if (multipleSelection) {
            let filterState = this.props.articleResiduals.articleResidualFilters[filterKey];
            filterState.includes(filterValue) ? filterState = filterState.filter(item => item !== filterValue) : filterState.push(filterValue);
            filterValue = filterState;
        }
        this.props.updateFiltersForArticleResidualList(filterKey, filterValue)
    }

    handleFilterReset(carrierCode) {
        this.props.clearArticleResidualFilters();
        this.props.getArticleResidualList(`orderBy=createdAt&sort=desc&size=100&page=1&filterFields[]=carrierCode&filterValues[]=${carrierCode}`);
    }

    fetchResidualList(carrierCode, pageNumber) {
        this.props.getArticleResidualList(`orderBy=createdAt&sort=desc&size=100&page=${pageNumber}&filterFields[]=carrierCode&filterValues[]=${carrierCode}&${parseObjectToString(this.props.articleResiduals.articleResidualFilters)}`)
    }

    renderList(residualList, showContentLoader) {
        const numberOfMonths = 39;

        let fields = [
            {
                name: 'sku',
            },
            {
                name: 'description',
                label: "articleDescription"
            },
            {
                name: 'suggestedRetailPriceLimit',
                label: 'article.residual.srp'
            },

        ];

        const filterKeys = [
            {
                name: 'sku',
                type: 'textField'
            },
            {
                name: 'description',
                type: 'textField'
            }
        ];

        for (let i=1; i <= numberOfMonths; i++) {
            fields.push({
                name: i + 'm',
                label: 'article.residual.month'
            })
        }

        return (
            <ArticleResidualTable fields={fields}
                itemsResponse={residualList}
                entity="articleResidual"
                filterKeys={filterKeys}
                filterValues={this.props.articleResiduals.articleResidualFilters}
                onInputChange={(filterKey, filterValue, multipleSelection) => this.handleFilterUpdate(filterKey, filterValue, multipleSelection)}
                onValueSubmit={(carrierCode) => this.props.getArticleResidualList(`orderBy=createdAt&sort=desc&size=100&page=1&filterFields[]=carrierCode&filterValues[]=${carrierCode}&${parseObjectToString(this.props.articleResiduals.articleResidualFilters)}`)}
                sorting={this.props.articleResiduals.sorting}
                sortingAvailable={false}
                onCancel={(carrierCode) => this.handleFilterReset(carrierCode)}
                showContentLoader={showContentLoader}
                enableFilter={true}
                disableCell={false}
                countryCarrier={this.props.articleResiduals.articleResidualCountryCarrier}
                numberOfMonths={numberOfMonths}
                fetchData={(carrierCode, pageNumber) => this.fetchResidualList(carrierCode, pageNumber)}
            />
        )
    }

    render() {

        const MenuBar = () => {
            const { t } = useTranslation();
            return (
                <div style={{
                    paddingTop: 30,
                    paddingLeft: 30,
                    paddingRight: 30,
                    display: "flex",
                    justifyContent: "flex-end"
                }} >
                    {
                        validateAuthority(AUTHORITY_ADMIN,this.props.authentication.loggedInUser.authorities) &&  
                        <Link to={`/articles/residual/upload`} seleniumselector={`/articles/residual/upload`}>
                            <Button size="small" variant="contained" color="secondary" style={{
                           background: "#E3D900",
                           padding: "16px"
                        }} className="margin-left-10">
                                {t('messages.residual.upload')}
                                <NoteAdd  style={{
                           marginLeft: "8px"
                        }} />
                            </Button>
                        </Link>
                    }
                </div>
            )
        }

        return (
            <>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <h3 style={{ paddingLeft: 30, paddingTop: 30 }}>{"Residual Value Listing"}</h3>
                    <MenuBar></MenuBar>
                </Box>

                <PageTemplate uploadAction={"upload"}>
                    {this.renderList(this.props.articleResiduals.articleResidualList, this.props.common.showContentLoader)}
                </PageTemplate>
            </>

        )
    }

}

const mapStateToProps = state => ({
    articleResiduals: state.articleResiduals,
    authentication: state.authentication,
    common: state.common
})

export default connect(mapStateToProps, {
    getArticleResidualList: getArticleResidualList,
    updateFiltersForArticleResidualList: updateFiltersForArticleResidualList,
    clearArticleResidualFilters: clearArticleResidualFilters,
    sortArticleResidual: sortArticleResidual,
    getArticleResidualCountryCarrier: getArticleResidualCountryCarrier,
    getArticleResidualCSVTemplate: getArticleResidualCSVTemplate,
    showError
})(ArticleResidualList);
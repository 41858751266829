import React, {Component} from "react";
import { connect } from 'react-redux';
import ArticleUploadAccordion from "../../components/Article/ArticleUploadAccordion";
import ArticlePreviewAccordion  from "../../components/Article/ArticlePreviewAccordion";
import { showDownloadDialog, hideDownloadDialog } from "../../actions/commonActions";
import { validateAuthority } from "../../utils/utils";
import { showError } from "../../actions/commonActions";
import TemplateDownloadDialog from "../../components/dialogs/TemplateDownloadDialog";
import { USER_COUNTRY_CODES, AUTHORITY_ADMIN } from "../../constant";
import ArticleResidualPreviewTable from "../../components/Article/ArticleResidualPreviewTable"
import { uploadArticleResidualCSV, getArticleResidualCSVTemplate, submitArticleResidual, hideArticleResidualUploadAccordion } from "../../actions/articleResidualAction"

class ArticleResidualUpload extends Component {

    componentDidMount() {
        this.props.hideArticleResidualUploadAccordion();
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.common.dialogType !== prevProps.common.dialogType &&
            this.props.common.dialogType === 'success'
        ) {
            this.props.history.push('/articles/residual');
        }
    }


    previewUpload = (file, operationType) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.props.uploadArticleResidualCSV({"uploadType": operationType ,"data": reader.result?.split(',')[1]})
        } 

    }

    onSubmit = (data) => {
        this.props.submitArticleResidual(data.newArticlesResidual)
        this.props.hideArticleResidualUploadAccordion();
    }

    handleServerSideCSVTemplateDownload = (data) => {
        this.props.getArticleResidualCSVTemplate(data);
    }

    onDownloadDialogOpen = () => {
        this.props.showDownloadDialog();
    }

    onDownloadDialogClose = () => {
        this.props.hideDownloadDialog();
    }

    renderUploadAccordion = () => {
        const styles = {
            main: {
                paddingTop: 30,
                paddingLeft: 30,
                paddingRight: 30,
            }
        }

        return (
            <div style={styles.main}>
                <ArticleUploadAccordion
                    processCSVFile={(file, operationType) => this.previewUpload(file, operationType)}
                    templateFile={`template_article_rv_admin.csv`}
                    open={this.props.articleResiduals.articleResidualUploadAccordion.uploadAccordion}
                ></ArticleUploadAccordion>
            </div>
        )

    }

    renderPreviewAccordion = () => {
        const styles = {
            main: {
                paddingTop: 30,
                paddingLeft: 30,
                paddingRight: 30,
            }
        }

        return (
            <div style={styles.main}>
                <ArticlePreviewAccordion
                    open={this.props.articleResiduals.articleResidualUploadAccordion.previewAccordion}
                    entitySize={this.props.articleResiduals.articleResidualUploadAccordion.entity ? this.props.articleResiduals.articleResidualUploadAccordion.entity.numberOfRecordsProcessed : null}
                    entity={this.props.articleResiduals.articleResidualUploadAccordion.entity}
                    onSubmit={(data) => this.onSubmit(data)}
                >
                    <ArticleResidualPreviewTable
                        entity={this.props.articleResiduals.articleResidualUploadAccordion.entity}
                    >
                    </ArticleResidualPreviewTable>
                </ArticlePreviewAccordion>
            </div>
        )

    }

    renderDownloadTemplateDialog = () => {
        return (
            <TemplateDownloadDialog
                open={this.props.common.downloadDialog.open}
                countryCodes={validateAuthority(AUTHORITY_ADMIN,this.props.authentication.loggedInUser.authorities) ? USER_COUNTRY_CODES : this.props.authentication.loggedInUser.availableCountryCodes} 
                onSubmit={(data) => this.handleServerSideCSVTemplateDownload(data)}
                onReject={() => this.onDownloadDialogClose()}
            ></TemplateDownloadDialog>
        )
    }

    render() {
        
        return(
            <>
                {
                    this.renderUploadAccordion()
                }

                {
                    this.renderPreviewAccordion()
                }
                {
                    this.renderDownloadTemplateDialog()
                }
            </>
        )
    }

}

const mapStateToProps = state => ({
    articleResiduals: state.articleResiduals,
    authentication: state.authentication,
    common: state.common
})

export default connect(mapStateToProps, {
    hideArticleResidualUploadAccordion: hideArticleResidualUploadAccordion,
    uploadArticleResidualCSV: uploadArticleResidualCSV,
    submitArticleResidual : submitArticleResidual,
    showDownloadDialog: showDownloadDialog,
    hideDownloadDialog: hideDownloadDialog,
    getArticleResidualCSVTemplate: getArticleResidualCSVTemplate,
    showError: showError
})(ArticleResidualUpload);
import {applyMiddleware, combineReducers, createStore} from "redux";
import thunk from "redux-thunk";
import {composeWithDevTools} from "redux-devtools-extension";
import authenticationReducer from "../reducers/authenticationReducer";
import commonReducer from "../reducers/commonReducer";
import userReducer from "../reducers/userReducer";
import articleReducer from "../reducers/articleReducer";
import accessoryMappingReducer from "../reducers/accessoryMappingReducer";
import articleLikeForReducer from "../reducers/articleLikeForReducer";
import severityReducer from "../reducers/severityReducer";
import articleLikeForUpdateRequestReducer from "../reducers/articleLikeForRequestReducer";
import articleLikeForRefreshReducer from "../reducers/articleLikeForRefreshReducer";
import articleExclusionReducer from "../reducers/articleExclusionReducer";
import articleAuditReducer from "../reducers/articleAuditReducer";
import articleResidualReducer from "../reducers/articleResidualReducer"

const rootReducer = combineReducers({
    authentication: authenticationReducer,
    common: commonReducer,
    users: userReducer,
    articles: articleReducer,
    accessoryMappings: accessoryMappingReducer,
    severities: severityReducer,
    articleLikeFors: articleLikeForReducer,
    articleLikeForUpdateRequests: articleLikeForUpdateRequestReducer,
    articleLikeForRefresh: articleLikeForRefreshReducer,
    articleExclusions: articleExclusionReducer,
    articleAudits: articleAuditReducer,
    articleResiduals: articleResidualReducer
});

export default function configureStore() {
    const middlewares = [thunk];
    const middleWareEnhancer = applyMiddleware(...middlewares);

    return createStore(
        rootReducer,
        composeWithDevTools(middleWareEnhancer)
    );
}
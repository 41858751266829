import {
    ARTICLE_RESIDUAL_GET_LIST,
    ARTICLE_RESIDUAL_FILTER,
    ARTICLE_RESIDUAL_CLEAR_FILTER,
    ARTICLE_RESIDUAL_SORT,
    ARTICLE_RESIDUAL_COUNTRY_CARRIER,
    SHOW_ARTICLE_RESIDUAL_UPLOAD_ACCORDION,
    HIDE_ARTICLE_RESIDUAL_UPLOAD_ACCORDION
} from "../actions/types";

const INITIAL_STATE = {
    articleResidualList: [],
    sorting: {
        orderBy: 'createdAt',
        sort: 'desc'
    },
    articleResidualFilters: {
        sku: '',
        'description': ''
    },
    articleResidualUploadAccordion: {
        entity: null,
        uploadAccordion: true,
        previewAccordion: false
    },
    articleResidualCountryCarrier: {}
}

const articleResidualReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ARTICLE_RESIDUAL_GET_LIST:
            return {
                ...state,
                articleResidualList: action.payload.data
            }
        case ARTICLE_RESIDUAL_FILTER:
            return {
                ...state,
                articleResidualFilters: {
                    ...state.articleResidualFilters,
                    [action.payload.filterKey]: action.payload.filterValue
                }
            }
        case ARTICLE_RESIDUAL_CLEAR_FILTER:
            return {
                ...state,
                articleResidualFilters: {
                    sku: '',
                    'description': ''
                }
            }
        case ARTICLE_RESIDUAL_SORT:
            return {
                ...state,
                sorting: action.payload
            }
        case SHOW_ARTICLE_RESIDUAL_UPLOAD_ACCORDION:
            return {
                ...state,
                articleResidualUploadAccordion: {
                    entity: action.payload.entity,
                    uploadAccordion: false,
                    previewAccordion: true
                }
            }
        case HIDE_ARTICLE_RESIDUAL_UPLOAD_ACCORDION:
            return {
                ...state,
                articleResidualUploadAccordion: {
                    entity: null,
                    uploadAccordion: true,
                    previewAccordion: false
                }
            }  
        case ARTICLE_RESIDUAL_COUNTRY_CARRIER:
            return {
                ...state,
                articleResidualCountryCarrier: action.payload.data,
            } 
        default:
            return state;
    }
}

export default articleResidualReducer;
import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import {TableContainer, Table, TableRow, TableCell, TableBody } from "@material-ui/core";
import { LOCALISED_ARTICLES_DIALOG_TABLE_HEADERS, LOCALISED_ARTICLES_DIALOG_TABLE_VALUES } from '../../utils/CsvObjectModifier/CsvObjectFormat';
import _ from 'lodash';
import { shortDate } from '../../utils/utils';
import { render } from '@testing-library/react';

const customStyles = makeStyles({
    fileUpload: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 10
    },
    sticky: {
        position: "sticky",
        left: 0,
        background: "white",
    },
    headers: {
        position: "sticky",
        top: 0,
        background: "white",
        zIndex: 2
    }
})

const ArticleResidualPreviewTable = ({entity}) => {
    const { t } = useTranslation();
    const customStyleClass = customStyles();
    const numberOfMonths = 39;

    const renderCell = (record, fieldKey, t) => {
        switch (fieldKey.type) {
            case 'shortDate':
                return shortDate(_.get(record, fieldKey.key), null);
            default:
                return record;
        }
    }
      
    return (
        <TableContainer style={{ maxHeight: 300}}>
            <Table aria-label="a dense table">
                <TableBody>
                    <TableRow className={customStyleClass.headers}>
                        <TableCell className={customStyleClass.sticky}><b>{t('keys.article.sku.keyword')}</b></TableCell>
                        <TableCell><b>{t('keys.article.description.keyword')}</b></TableCell>
                        <TableCell><b>{t('keys.article.residual.srp')}</b></TableCell>
                        <TableCell><b>{t('keys.article.residual.carrierCode')}</b></TableCell>
                        {
                            Array.from({ length: numberOfMonths }, (_, index) => (
                                <TableCell><b>{t('keys.article.residual.month', {value: `${index + 1}m`})}</b></TableCell>
                            ))
                        }     
                    </TableRow>
                        {
                            entity != null ? (
                                entity?.newArticlesResidual.map((record, index) => {
                                    return (
                                    <TableRow>
                                        <TableCell key={`${index}-sku`} className={customStyleClass.sticky}> { record.sku }</TableCell>
                                        <TableCell key={`${index}-description`}> { record.description }</TableCell>
                                        <TableCell key={`${index}-srp`}> { record.suggestedRetailPriceLimit }</TableCell>
                                        <TableCell key={`${index}-carrierCode`}> { record.carrierCode }</TableCell>
                                        {
                                            Array.from({ length: numberOfMonths }, (_, index) => {
                                                const priceValue = index < record.residualPrice.length 
                                                    ? record.residualPrice[index].value 
                                                    : '';  

                                                return (
                                                    <TableCell>{priceValue}</TableCell>
                                                );
                                            })
                                        } 
                                    </TableRow>
                                    )
                                })     
                            ) : (<TableRow></TableRow>)
                        }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ArticleResidualPreviewTable;
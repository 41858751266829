import axios from "./interceptor";
import { BASE_URL } from "../constant";
import {
    ARTICLE_RESIDUAL_GET_LIST,
    ARTICLE_RESIDUAL_FILTER,
    ARTICLE_RESIDUAL_CLEAR_FILTER,
    ARTICLE_RESIDUAL_SORT,
    ARTICLE_RESIDUAL_COUNTRY_CARRIER,
    SHOW_ARTICLE_RESIDUAL_UPLOAD_ACCORDION,
    HIDE_ARTICLE_RESIDUAL_UPLOAD_ACCORDION,
    SHOW_SUCCESS,
    SHOW_ERROR,
} from "./types";
import { handleError, showContentLoader, handleMultipleError } from "../utils/utils";
import {showError} from './commonActions';

export const getArticleResidualList = (path) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/current/article/getArticleResidual?${path}`)
        .then(response => {
            dispatch({
                type: ARTICLE_RESIDUAL_GET_LIST,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}

export const updateFiltersForArticleResidualList = (filterKey, filterValue) => {
    return({
        type: ARTICLE_RESIDUAL_FILTER,
        payload: {filterKey, filterValue}
    })
}

export const clearArticleResidualFilters = () => {
    return ({
        type: ARTICLE_RESIDUAL_CLEAR_FILTER
    })
}

export const sortArticleResidual = (orderBy, sort) => {
    return ({
        type: ARTICLE_RESIDUAL_SORT,
        payload: {orderBy, sort}
    })
}

export const getArticleResidualCountryCarrier = (path) => async dispatch => {
    showContentLoader(true, dispatch);
    try {
        let response = await axios.get(`${BASE_URL}/current/config/${path}`)

        console.log("response", response)
        
        dispatch({
            type: ARTICLE_RESIDUAL_COUNTRY_CARRIER,
            payload: response
        })
    } catch (error) {
        handleError(error, dispatch);
    }
    showContentLoader(false, dispatch);
}

export const getArticleResidualCSVTemplate = (data) => async dispatch => {
    axios.post(`${BASE_URL}/current/config/articleResidualFile`, data, {
        responseType: 'blob'
    })
    .then(response => {
        dispatch(() => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(response.data);
            link.download = 'Template_article_rv_admin.csv';
            link.click();
        })
        }).catch(error => {
            handleError(error, dispatch)
        })
}

export const uploadArticleResidualCSV = (payload) => async dispatch => {
    axios.post(`${BASE_URL}/current/article/upload/articleResidual`, payload)
        .then(response => {
            if(response.data.numberOfRecordsProcessed == 0) {
                dispatch(showError({ response: { data: { translationKey: `0 record uploaded. Please fix and try again.` } } }))
            } else {
                dispatch({
                    type: SHOW_ARTICLE_RESIDUAL_UPLOAD_ACCORDION,
                    payload: {
                        entity: response.data
                    }
                })
            }
        }).catch(error => {
            if (error.response.data.errorCode == "500" || error.response.data.errorCode == "400") {
                dispatch({
                    type: SHOW_ERROR,
                    payload:  { data: { translationKey: error.response.data.message?.replace(/:/g, '__') } } 
                })
            } else {
                handleError(error, dispatch)
            }
        })
}

export const submitArticleResidual = (articleResiduals) => async dispatch => {
    console.log("Submit Article Residuals "+ JSON.stringify(articleResiduals) );

    axios.post(`${BASE_URL}/current/article/upsertArticlesResidual`, articleResiduals)
        .then(response => {
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.user.fileUploaded',
                    dialogType: 'success',
                    refresh: true
                }
            })
        }).catch(error => {
            if (error.response.data.errorCode == "500" || error.response.data.errorCode == "400") {
                dispatch({
                    type: SHOW_ERROR,
                    payload:  { data: { translationKey: error.response.data.message?.replace(/:/g, '__') } } 
                })
            } else {
                handleError(error, dispatch)
            }
        })
}

export const hideArticleResidualUploadAccordion = () => {
    return ({
        type: HIDE_ARTICLE_RESIDUAL_UPLOAD_ACCORDION,
        payload: {
            entity: null
        }
    })
}